import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { Section } from "../styles/structures";
import { H3, P } from "../styles/text-styles";

export default function ImageWithText() {
  return (
    <Section>
      <Wrapper>
        <Image src="https://uploads-ssl.webflow.com/5e27ae619b1d658aaf0ca938/5facb65f914e104a81aad877_landing-page-image.jpg"></Image>
        <Panel>
          <Headline>
            Find your next setup.{" "}
            <span>
              Look, we get it. Everyone wants to ride what the pros are riding.
              Let us help you.
            </span>
          </Headline>
          <BodyText>
            Everyone's gear buying process is a little different. Maybe you
            start with a broad search and narrow down by features or price
            range. Maybe you're brand loyal. Maybe you see what your favorite
            skier or snowboarder is riding. Well there's the hard part. Not
            every company lists their rider's setup and not every rider has done
            an interview explaining exactly what boots and bindings they’re
            using this season. That's where we come in. We've done the research
            for you, so you can instantly find out what your favorite athlete is
            riding, and recreate their game. We can’t guarantee you’ll ride like
            them, but it’s a good place to start.
          </BodyText>
        </Panel>
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  ${breakpoints.lg} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Image = styled.img`
  border-radius: 12px;
  display: inline-block;
  max-width: 100%;
`;

const Panel = styled.div`
  background-color: ${colors.gray2};
  border-radius: 12px;

  padding: 24px;

  ${breakpoints.md} {
    padding: 36px;
  }
`;

const Headline = styled(H3)`
  color: ${colors.primary2};
  margin-bottom: 24px;

  span {
    color: ${colors.white};
  }
`;

const BodyText = styled(P)`
  color: ${colors.gray4};
`;
