import * as React from "react";
import styled from "styled-components";

import Hero from "../components/Hero";
import ImageWithText from "../components/ImageWithText";
import PartnerPanel from "../components/PartnerPanel";
import TopAthletes from "../components/TopAthletes";
import AdBanner from "../components/banners/AdBanner";
import CtaBanner from "../components/banners/CtaBanner";
import Layout from "../components/meta/Layout";
import Seo from "../components/meta/Seo";
import { breakpoints } from "../styles/breakpoints";

const IndexPage = () => (
  <>
    <meta
      name="fo-verify"
      content="0ce78011-5ded-46e5-84f1-eeecf2f4abbd"
    ></meta>

    <Layout>
      <Seo
        title="Home"
        description="The best way to see what gear your favorite pros are riding. Ski and Snowboard athlete gear search."
      />
      <Hero />
      <AdBannerWrapper>
        <AdBanner pageLocation="homepage" />
      </AdBannerWrapper>
      <TopAthletes />
      <ImageWithText />
      <PartnerPanel />
      <CtaBanner />
    </Layout>
  </>
);

const AdBannerWrapper = styled.div`
  margin: -30px 0;

  ${breakpoints.md} {
    margin: -64px 0;
  }
`;

export default IndexPage;
