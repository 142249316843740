import * as React from "react";
import styled from "styled-components";

import { colors } from "../styles/colors";
import { ContentWrapper, Section } from "../styles/structures";
import { H1, H4, H5 } from "../styles/text-styles";
import Button from "./buttons/Button";

export default function Hero() {
  return (
    <Section>
      <ContentWrapper>
        <Eyebrow>The best way to</Eyebrow>
        <Headline>See what your favorite pros are riding.</Headline>
        <Subheadline>
          Fast and easy.{" "}
          <span>Skiing and Snowboarding athlete gear search.</span>
        </Subheadline>
        <ButtonWrapper>
          <Button link="/athletes" title="Explore our athletes" />
        </ButtonWrapper>
      </ContentWrapper>
    </Section>
  );
}

const Eyebrow = styled(H5)`
  color: ${colors.primary2};
  margin-bottom: 24px;
`;

const Headline = styled(H1)`
  margin-bottom: 36px;
`;

const Subheadline = styled(H4)`
  span {
    color: ${colors.gray4};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: center;
`;
