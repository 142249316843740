import React from "react";
import styled from "styled-components";

import Backcountry from "../images/backcountry.svg";
import Evo from "../images/evo.svg";
import REI from "../images/rei.svg";
import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { ContentWrapper, Section } from "../styles/structures";
import { H4 } from "../styles/text-styles";

export default function PartnerPanel() {
  return (
    <Section>
      <Panel>
        <ContentWrapper>
          <BodyText>
            Shop from the best retailers.{" "}
            <span>
              We partner with the biggest retailers to deliver you a high
              quality shopping experience.
            </span>
          </BodyText>
          <CenterLogos>
            <a
              id="homepage_evo_click"
              href="https://bit.ly/3KbmOam"
              target="_blank"
            >
              <LogoImg src={Evo} alt="Evo" />
            </a>
            <a
              id="homepage_backcountry_click"
              href="https://bit.ly/3tjptrP"
              target="_blank"
            >
              <LogoImg src={Backcountry} alt="Backcountry" />
            </a>
            <a
              id="homepage_rei_click"
              href="https://bit.ly/41XB2EP"
              target="_blank"
            >
              <LogoImg src={REI} alt="REI" />
            </a>
          </CenterLogos>
        </ContentWrapper>
      </Panel>
    </Section>
  );
}

const Panel = styled.div`
  background-color: ${colors.gray2};
  border-radius: 12px;

  padding-top: 72px;
  padding-bottom: 72px;

  ${breakpoints.md} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`;

const BodyText = styled(H4)`
  margin-bottom: 36px;

  span {
    color: ${colors.gray4};
  }
`;

const CenterLogos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 50px;
  margin: 12px;
  vertical-align: middle;

  :hover {
    filter: brightness(0.9);
  }
`;
