import React from "react";
import styled from "styled-components";

import { colors } from "../../styles/colors";
import { Section } from "../../styles/structures";
import { H2, H4 } from "../../styles/text-styles";
import Button from "../buttons/Button";

export default function CtaBanner() {
  return (
    <Section>
      <Headline>Check out the athletes.</Headline>
      <Subheadline>
        Our list of riders is always growing.{" "}
        <span>See who we've collected so far.</span>
      </Subheadline>
      <ButtonWrapper>
        <Button link="/athletes" title="Explore our athletes" />
      </ButtonWrapper>
    </Section>
  );
}

const Headline = styled(H2)`
  text-align: center;
  margin-bottom: 32px;
`;

const Subheadline = styled(H4)`
  text-align: center;
  margin-bottom: 36px;

  span {
    color: ${colors.gray4};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
