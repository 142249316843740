import { Link, graphql, useStaticQuery } from "gatsby";
import { any } from "prop-types";
import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/breakpoints";
import { colors } from "../styles/colors";
import { ContentWrapper, Section } from "../styles/structures";
import { H1, H4, H5 } from "../styles/text-styles";
import ListPanel, { ListItemWithLink } from "./panels/ListPanel";

export default function TopAthletes() {
  const {
    allContentfulHomePageContent: { nodes: data },
  } = useStaticQuery(
    graphql`
      {
        allContentfulHomePageContent {
          nodes {
            popularSnowboarders {
              name
              slug
              sport
            }
            popularSkiers {
              name
              slug
              sport
            }
          }
        }
      }
    `
  );

  const topSnowboarders = data[0].popularSnowboarders.map(
    (snowboarder: any) => {
      return {
        value: snowboarder.name,
        url: `/athletes/${snowboarder.sport}/${snowboarder.slug}`,
        slug: true,
      } as ListItemWithLink;
    }
  );

  const topSkiers = data[0].popularSkiers.map((skier: any) => {
    return {
      value: skier.name,
      url: `/athletes/${skier.sport}/${skier.slug}`,
      slug: true,
    } as ListItemWithLink;
  });

  return (
    <Section>
      <Wrapper>
        <ListPanel
          title="Most Popular Snowboarders"
          elementId="popular_snowboarders_click"
          color={colors.primary3}
          items={topSnowboarders}
        />
        <ListPanel
          title="Most Popular Skiers"
          elementId="popular_skiers_click"
          color={colors.primary3}
          items={topSkiers}
        />
      </Wrapper>
    </Section>
  );
}

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  gap: 18px;

  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const Eyebrow = styled(H5)`
  color: ${colors.primary2};
  margin-bottom: 24px;
`;

const Headline = styled(H1)`
  margin-bottom: 36px;
`;

const Subheadline = styled(H4)`
  span {
    color: ${colors.gray4};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: center;
`;
